import { formatNumber, NumberType } from '@ethena/shared-ui'
import { PENDLE_SENA } from '@ethena/shared-ui/src/constants/pendle-tokens-and-links'
import { SupportedChain } from '@ethena/shared-ui/src/types/chains'
import { PendleMarketResponse } from '@ethena/shared-ui/src/types/pendle-response'

import { useMemo } from 'react'
import useSWR from 'swr'

export const usePendleSenaAPY = () => {
  const { data } = useSWR<PendleMarketResponse>(
    `/api/airdrop/pendle-market?chain=${SupportedChain.MAINNET}&lpAddress=${PENDLE_SENA}`,
    {
      refreshInterval: 1000 * 60 * 60,
    },
  )

  return useMemo(() => {
    if (!data) return { apy: undefined }
    const apy = formatNumber(Math.round(data.maxBoostedApy), NumberType.NoDecimals) + '%'

    return { apy }
  }, [data])
}
