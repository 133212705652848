export type PendleToken = 'PT' | 'YT' | 'SY' | 'LP'

export const PENDLE_USDE_ARBITRUM_NOVEMBER = '0x281fe15fd3e08a282f52d5cf09a4d13c3709e66d'
export const PENDLE_USDE_ARBITRUM_NOVEMBER_LINK =
  'https://app.pendle.finance/trade/points/0x281fe15fd3e08a282f52d5cf09a4d13c3709e66d?chain=arbitrum'

export const PENDLE_ENA = '0x9C73879F795CefA1D5239dE08d1B6Aba2D2d1434'
export const PENDLE_ENA_LINK = 'https://app.pendle.finance/points/ENA-0x9c73879f795cefa1d5239de08d1b6aba2d2d1434'

export const PENDLE_SUSDE_SEPTEMBER = '0xd1D7D99764f8a52Aff007b7831cc02748b2013b5'

export const PENDLE_SUSDE_SEPTEMBER_LINK =
  'https://app.pendle.finance/trade/points/0xd1d7d99764f8a52aff007b7831cc02748b2013b5?chain=ethereum'

export const PENDLE_KARAK_USDE = '0x6c06bbfa3b63ed344ceb3312df795edc8d29bdd5'
export const PENDLE_KARAK_USDE_LINK =
  'https://app.pendle.finance/trade/points/0x6c06bbfa3b63ed344ceb3312df795edc8d29bdd5?chain=ethereum'

export const PENDLE_KARAK_SUSDE = '0xdbe4d359d4e48087586ec04b93809ba647343548'

export const PENDLE_KARAK_SUSDE_LINK =
  'https://app.pendle.finance/trade/points/0xdbe4d359d4e48087586ec04b93809ba647343548?chain=ethereum'

export const PENDLE_RSENA = '0xf68300929df14d933eba2d45917563d5ed065666'
export const PENDLE_RSENA_LINK =
  'https://app.pendle.finance/trade/points/0xf68300929df14d933eba2d45917563d5ed065666?chain=ethereum'
export const PENDLE_RSENA_MAR = '0x7e0209ab6fa3c7730603b68799bbe9327dab7e88'
export const PENDLE_RSENA_MAR_LINK =
  'https://app.pendle.finance/trade/points/0x7e0209ab6fa3c7730603b68799bbe9327dab7e88?chain=ethereum'

export const PENDLE_RSUSDE = '0x890b6afc834c2a2cc6cb9b6627272ab4ecfd8271'
export const PENDLE_RSUSDE_LINK =
  'https://app.pendle.finance/trade/points/0x890b6afc834c2a2cc6cb9b6627272ab4ecfd8271?chain=ethereum'

export const PENDLE_USDE_OCT_LINK =
  'https://app.pendle.finance/trade/points/0x3d1e7312de9b8fc246dded971ee7547b0a80592a?chain=ethereum'

export const PENDLE_USDE_OCT = '0x3d1e7312de9b8fc246dded971ee7547b0a80592a'

export const PENDLE_USDE_DEC_LINK =
  'https://app.pendle.finance/trade/points/0x8a49f2ac2730ba15ab7ea832edac7f6ba22289f8?chain=ethereum'

export const PENDLE_USDE_DEC = '0x8a49f2ac2730ba15ab7ea832edac7f6ba22289f8'

export const PENDLE_USDE_MAR_LINK =
  'https://app.pendle.finance/trade/pools/0xb451a36c8b6b2eac77ad0737ba732818143a0e25?chain=ethereum'

export const PENDLE_USDE_MAR = '0xb451a36c8b6b2eac77ad0737ba732818143a0e25'

export const PENDLE_SUSDE_OCT_LINK =
  'https://app.pendle.finance/trade/points/0xbbf399db59a845066aafce9ae55e68c505fa97b7?chain=ethereum'

export const PENDLE_SUSDE_OCT = '0xbbf399db59a845066aafce9ae55e68c505fa97b7'

export const PENDLE_SUSDE_DEC_LINK =
  'https://app.pendle.finance/trade/points/0xa0ab94debb3cc9a7ea77f3205ba4ab23276fed08?chain=ethereum'

export const PENDLE_SUSDE_DEC = '0xa0ab94debb3cc9a7ea77f3205ba4ab23276fed08'

export const PENDLE_SUSDE_MAR_LINK =
  'https://app.pendle.finance/trade/pools/0xcdd26eb5eb2ce0f203a84553853667ae69ca29ce?chain=ethereum'

export const PENDLE_SUSDE_MAR = '0xcdd26eb5eb2ce0f203a84553853667ae69ca29ce'

export const PENDLE_MANTLE_USDE_DEC_LINK =
  'https://app.pendle.finance/trade/points/0x2ddd4808fbb2e08b563af99b8f340433c32c8cc2?chain=mantle'

export const PENDLE_MANTLE_USDE_DEC = '0x2ddd4808fbb2e08b563af99b8f340433c32c8cc2'

export const PENDLE_ENA_OCT = '0x403829bce022844f66cdb2911d2260526d4eb843'
export const PENDLE_ENA_OCT_LINK =
  'https://app.pendle.finance/trade/points/0x403829bce022844f66cdb2911d2260526d4eb843?chain=ethereum'
export const PENDLE_SENA = '0x487e1cef7805cf0225dec3dd0f3044fe0fb70611'
export const PENDLE_SENA_LINK =
  'https://app.pendle.finance/trade/points/0x487e1cef7805cf0225dec3dd0f3044fe0fb70611?chain=ethereum'

export type PendleTokenAddress =
  | typeof PENDLE_ENA
  | typeof PENDLE_ENA_OCT
  | typeof PENDLE_KARAK_SUSDE
  | typeof PENDLE_KARAK_USDE
  | typeof PENDLE_RSENA
  | typeof PENDLE_RSUSDE
  | typeof PENDLE_SUSDE_DEC
  | typeof PENDLE_SUSDE_OCT
  | typeof PENDLE_SUSDE_SEPTEMBER
  | typeof PENDLE_SUSDE_MAR
  | typeof PENDLE_USDE_ARBITRUM_NOVEMBER
  | typeof PENDLE_USDE_DEC
  | typeof PENDLE_USDE_OCT
  | typeof PENDLE_USDE_MAR
  | typeof PENDLE_MANTLE_USDE_DEC
  | typeof PENDLE_RSENA_MAR
  | typeof PENDLE_SENA

export const PENDLE_SATS_BY_POOL = {
  [PENDLE_ENA]: 30,
  [PENDLE_ENA_OCT]: 30,
  [PENDLE_KARAK_SUSDE]: 5,
  [PENDLE_KARAK_USDE]: 20,
  [PENDLE_MANTLE_USDE_DEC]: 30,
  [PENDLE_RSENA]: 30,
  [PENDLE_RSENA_MAR]: 40,
  [PENDLE_RSUSDE]: 10,
  [PENDLE_SENA]: 40,
  [PENDLE_SUSDE_DEC]: 25,
  [PENDLE_SUSDE_MAR]: 30,
  [PENDLE_SUSDE_OCT]: 20,
  [PENDLE_SUSDE_SEPTEMBER]: 20,
  [PENDLE_USDE_ARBITRUM_NOVEMBER]: 20,
  [PENDLE_USDE_DEC]: 30,
  [PENDLE_USDE_MAR]: 35,
  [PENDLE_USDE_OCT]: 25,
}
